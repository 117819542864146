import { Accordion, AccordionDetails, AccordionSummary, Button, createStyles, Drawer, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { FormApi, SubmissionErrors, ValidationErrors } from 'final-form';
import React from 'react';
import { Field, FieldInputProps, Form } from 'react-final-form';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IAutoCompleteItem } from '../typings/autoComplete';
import { DropzoneArea } from 'material-ui-dropzone';
import { DocumentFile } from '../types/DocumentFile';
import { AreaOfLawSelector } from '../enquirySummary/selectors/AreaOfLawSelector';
import { client } from '../';
import { SendInstructMatterMutation } from './InstructMatterRepositories';
import { showNotification } from '../App';
import { CurrencyInput2 } from '../components/CurrencyInput';
import { OtherPartyTypeSelector } from '../lookupConfiguration/selectors/OtherPartyTypeSelector';
import { finalFormValidateEmail } from '../helpers/commonHelper';
import { Address, AddressSelector, PlaceType } from '../enquirySummary/selectors/AddressSelector';
import { useHomeCountry } from '../hooks/useHomeCountry';
import { useCurrencySymbol } from '../hooks/useCurrencySymbol';
import { RvLoader } from '../components/Loader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        paperRoot: {
            maxWidth: '700px', 
            padding: '15px',
        },
        controlWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        controlWrapperCol: {
            display: 'flex',
            alignItems: 'center',
            maring: '0 -10px',
            '& > button': {
                margin: '0 10px',
            },
        },
        contentWrapper: {
            padding: '15px 0',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            // fontWeight: theme.typography.fontWeightRegular,
            color: '#5173FF',
            display: 'flex',
            alignItems: 'center',
            flex: 1,
        },
        headingIcon: {
            marginRight: '10px',
        },
        textField: {
            width: '100%',
            minWidth: 'auto',
        },
        radioGroup: {
            flexFlow: 'row',
        },
        infoContainer: {
            display: 'flex',
            margin: '0 -10px',
            paddingLeft: '10px',
            justifyContent: 'flex-end',
            '& .col': {
                padding: '0 10px',
                display: 'flex',
                alignItems: 'center',
                '& .label': {
                    marginRight: '10px',
                },
                '& .files': {
                    fontWeight: 600,
                },
                '& .ellipsis': {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '250px',
                    textAlign: 'right',
                },
                '& .value': {
                    maxWidth: '400px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            },
        },
        dropZone: {
            minHeight: 'fit-content !important',
            '& .MuiDropzoneArea-root': {
                minHeight: 'fit-content !important',
            },
        },
        dropZoneFont: {
            '& .MuiDropzoneArea-text': {
                fontSize: '1em',
            },
        },
        title: {
            fontSize: '20px',
            marginBottom: '10px'
        },
        loaderWrapper: {
            minWidth: '700px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }),
);

interface InstructMatterFormDialogProps {
    open: boolean;
    onClose?: () => void;
}

interface InstructMatterFormControlValues {
    isSaveAndNew: boolean;
    isSaveAndClose: boolean;
}

interface MainPanelsProps {
    isDetailsPanelExpand: boolean;
}

interface InstructMatterFormValues extends InstructMatterFormControlValues, MainPanelsProps {
    isFormSubmit: boolean;
    areaOfLaw?: IAutoCompleteItem;
    instructions?: string;
    files: DocumentFile[];
    clientName?: string;
    clientContact?: string;
    matterTitle?: string;
    yourReference?: string;
    otherPartyType?: IAutoCompleteItem;
    otherPartyName?: string;
    otherPartyContact?: string;
    otherPartyAddress?: string;
    otherPartyPhone?: string;
    otherPartyEmail?: string;
    disputedAmount?: number;
}

export const InstructMatterFormDialog: React.FC<InstructMatterFormDialogProps> = (props) => {
    const classes = useStyles();

    const [refreshKey, setRefreshKey] = React.useState<number>(0);

    const homeCountryQuery = useHomeCountry();
    const currencyQuery = useCurrencySymbol();

    const initialValues: InstructMatterFormValues = {
        isFormSubmit: false,
        isSaveAndClose: false,
        isSaveAndNew: false,
        isDetailsPanelExpand: true,
        areaOfLaw: undefined,
        instructions: undefined,
        files: [],
        clientName: undefined,
        clientContact: undefined,
        matterTitle: undefined,
        yourReference: undefined,
        otherPartyType: undefined,
        otherPartyName: undefined,
        otherPartyContact: undefined,
        otherPartyAddress: undefined,
        otherPartyPhone: undefined,
        otherPartyEmail: undefined,
        disputedAmount: undefined,
    };

    const onSubmit = (values: InstructMatterFormValues, form: FormApi<InstructMatterFormValues, InstructMatterFormValues>, callback?: (errors?: SubmissionErrors) => void) => {
        if (values.areaOfLaw && values.instructions) {
            client.mutate({
                mutation: SendInstructMatterMutation,
                variables: {
                    input: {
                        areaOfLawGuid: values.areaOfLaw.value,
                        areaOfLaw: values.areaOfLaw.label,
                        instruction: values.instructions,
                        clientName: values.clientName,
                        clientContact: values.clientContact,
                        matterTitle: values.matterTitle,
                        yourReference: values.yourReference,
                        otherPartyType: values.otherPartyType ? values.otherPartyType.value : null,
                        otherPartyName: values.otherPartyName,
                        otherPartyContact: values.otherPartyContact,
                        otherPartyAddress: values.otherPartyAddress,
                        otherPartyPhone: values.otherPartyPhone,
                        otherPartyEmail: values.otherPartyEmail,
                        disputedAmount: values.disputedAmount,
                        files: values.files.map((document: DocumentFile) => {
                            return {
                                file: document.file,
                                name: document.name,
                                guidID: document.guidID,
                                status: document.status,
                            };
                        })
                    }
                }
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification('Failed to send instruct matter.', results.data.error, 'error');
                    } else {
                        if (values.isSaveAndNew) {

                            setRefreshKey((prevKey) => prevKey + 1);
                            form.setConfig('keepDirtyOnReinitialize', false);
                            form.batch(() => {
                                form.reset(initialValues);
                            });
                            form.setConfig('keepDirtyOnReinitialize', true);

                        } else {
                            if (props.onClose) {
                                props.onClose();
                            }
                        }

                        showNotification(null, 'Successfully submitted', 'info');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to send instruct matter', reason, 'error');
            });
        }
    };

    const onValdiate = (values: InstructMatterFormValues) => {
        return undefined;
    };

    // tslint:disable-next-line: no-any
    const onRequired = (value: any) => (value ? undefined : 'Required');

    // tslint:disable-next-line: max-line-length no-any
    const onExpandPanel = (input: FieldInputProps<InstructMatterFormValues, any>) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
        // code here..
        input.onChange(expanded);
    };

    const onSaveAndClose = (form: FormApi<InstructMatterFormValues>) => (event: React.MouseEvent<HTMLElement>) => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.change('isFormSubmit', true);
            form.change('isSaveAndClose', true);
            form.change('isSaveAndNew', false);
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSaveAndNew = (form: FormApi<InstructMatterFormValues>) => (event: React.MouseEvent<HTMLElement>) => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.change('isFormSubmit', true);
            form.change('isSaveAndClose', false);
            form.change('isSaveAndNew', true);
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    // tslint:disable-next-line: no-any
    const onFieldRequiredError = (errors: ValidationErrors, form: FormApi<InstructMatterFormValues | any>, values: InstructMatterFormValues) => {
        if (Object.keys(errors).length) {
            form.batch(() => {
                let isDetailsPanelExpand = false;

                if (
                    values.isDetailsPanelExpand ||
                    errors.hasOwnProperty('clientName') ||
                    errors.hasOwnProperty('clientContact') ||
                    errors.hasOwnProperty('matterTitle') ||
                    errors.hasOwnProperty('yourReference') ||
                    errors.hasOwnProperty('otherPartyType') ||
                    errors.hasOwnProperty('otherPartyName') ||
                    errors.hasOwnProperty('otherPartyContact') ||
                    errors.hasOwnProperty('otherPartyAddress') ||
                    errors.hasOwnProperty('otherPartyPhone') ||
                    errors.hasOwnProperty('otherPartyEmail') ||
                    errors.hasOwnProperty('disputedAmount') ||
                    errors.hasOwnProperty('areaOfLaw') ||
                    errors.hasOwnProperty('instructions')
                ) {
                    isDetailsPanelExpand = true;
                }

                form.change('isDetailsPanelExpand', isDetailsPanelExpand);
                form.change('isFormSubmit', false);
            });
        }
    };
    // tslint:disable-next-line: no-any
    const onInputChanged = (form: FormApi<any>, name: string | any) => (value: string) => {
        form.change(name, value);
    };

    const onAddressSelection = (input: FieldInputProps<InstructMatterFormValues>) => (placeType: PlaceType | null, address: Address, name: string) => {
        const { premise, floor, streetNumber, route, locality, administrativeAreaLevel1, postalCode, country } = address;

        const fullAddress: string = [premise, floor, streetNumber, route, locality, administrativeAreaLevel1, postalCode, country].filter(n => n).join(', ');
        
        input.onChange(fullAddress);

    };

    // tslint:disable-next-line: max-line-length
    const controlButtonComponent = (form: FormApi<InstructMatterFormValues>, submitting: boolean, pristine: boolean, values: InstructMatterFormValues) => {
        return (
            <div className={classes.controlWrapper}>
                <div className={classes.controlWrapperCol}>
                    <Button variant="contained" type="submit" disabled={submitting || pristine} onClick={onSaveAndClose(form)}>
                        Send & Close
                    </Button>
                    <Button variant="contained" type="submit" disabled={submitting || pristine} onClick={onSaveAndNew(form)}>
                        Send & New
                    </Button>
                </div>
                <div className={classes.controlWrapperCol}>
                    <Button variant="contained" type="button" onClick={props.onClose}>
                        Close
                    </Button>
                </div>
            </div>
        );
    };

    const detailPanel = (form: FormApi<InstructMatterFormValues>, submitting: boolean, pristine: boolean, values: InstructMatterFormValues) => {
        return (
            <Field name="isDetailsPanelExpand" subscription={{ touched: true, error: true, value: true }}>
                {(accordion) => {
                    return (
                        <Accordion defaultExpanded={true} expanded={accordion.input.value} onChange={onExpandPanel(accordion.input)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography className={classes.heading}>Details</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container={true} spacing={1}>
                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field name="areaOfLaw" subscription={{ touched: true, error: true, value: true }} validate={onRequired}>
                                            {({ input, meta }) => (
                                                <AreaOfLawSelector
                                                    {...input}
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    label="Matter Type"
                                                    required={true}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'required'
                                                            : 'Select the type of legal advice required.'
                                                    }
                                                    onSelection={(selection: IAutoCompleteItem) => {
                                                        input.onChange(selection);
                                                    }}
                                                    onBlur={input.onBlur}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field 
                                            name="matterTitle" 
                                            // validate={onRequired}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    label="Matter Title"
                                                    multiline={true}
                                                    helperText={meta.error && meta.touched ? meta.error : 'Enter the title or description of the matter'}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field 
                                            name="clientName" 
                                            // validate={onRequired}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    label="Client Name"
                                                    multiline={true}
                                                    helperText={meta.error && meta.touched ? meta.error : 'Enter the name of the client entity for the matter if different'}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field 
                                            name="clientContact" 
                                            // validate={onRequired}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    label="Client Contact"
                                                    multiline={true}
                                                    helperText={meta.error && meta.touched ? meta.error : 'Enter the contact name for the matter if different from the current user.'}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field 
                                            name="otherPartyType" 
                                            // validate={onRequired}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <OtherPartyTypeSelector
                                                    {...input}
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    label="Other Party Type"
                                                    // required={true}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'required'
                                                            : 'Select the role of the other party details provided below.'
                                                    }
                                                    onSelection={(selection: IAutoCompleteItem) => {
                                                        input.onChange(selection);
                                                    }}
                                                    onBlur={input.onBlur}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field 
                                            name="yourReference" 
                                            // validate={onRequired}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    label="Your Reference"
                                                    multiline={true}
                                                    helperText={meta.error && meta.touched ? meta.error : 'Enter your reference details for the matter'}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field 
                                            name="otherPartyName" 
                                            // validate={onRequired}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    label="Other Party Name"
                                                    multiline={true}
                                                    helperText={meta.error && meta.touched ? meta.error : 'Enter the other party name'}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field 
                                            name="otherPartyContact" 
                                            // validate={onRequired}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    label="Other Party Contact"
                                                    multiline={true}
                                                    helperText={meta.error && meta.touched ? meta.error : 'Enter the contact at the other party if applicable'}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field 
                                            name="otherPartyAddress" 
                                            // validate={onRequired}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <AddressSelector
                                                    {...input}
                                                    freeSolo={true}
                                                    label="Other Party Address"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    name="street2"
                                                    homeCountry={homeCountryQuery.data!.options.homeCountry.stringValue}
                                                    // tslint:disable-next-line: max-line-length
                                                    // placeTypes={['address', '(regions)', '(cities)']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                    helperText={
                                                        meta.error &&
                                                            meta.touched
                                                            ? meta.error
                                                            : 'Enter the other party address details if known'
                                                    }
                                                    onSelection={onAddressSelection(input)}
                                                    onInputChanged={onInputChanged(form, input.name)}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field 
                                            name="otherPartyPhone" 
                                            // validate={onRequired}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    label="Other Party Phone"
                                                    multiline={true}
                                                    helperText={meta.error && meta.touched ? meta.error : 'Enter the other party phone number if known'}
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field 
                                            name="otherPartyEmail" 
                                            validate={finalFormValidateEmail}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    label="Other Party Email"
                                                    multiline={true}
                                                    helperText={meta.error && meta.touched ? meta.error : 'Enter the other party email address if known'}
                                                    type="email"
                                                />
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                                        <Field
                                            name="disputedAmount"
                                            label="Disputed Amount"
                                            // helperText={'Debtors amount to write off'}
                                            subscription={{ touched: true, error: true, value: true }}
                                            prefix={currencyQuery.data!.options.currencySymbol.symbol}
                                            component={CurrencyInput2}
                                            allowNull={true}
                                            className={classes.textField}
                                            helperText="If a litigation or debt recovery matter enter the total amount in dispute"
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                                        <Field 
                                            name="instructions" 
                                            validate={onRequired}
                                            subscription={{ touched: true, error: true, value: true }}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    label="Instructions"
                                                    multiline={true}
                                                    helperText={meta.error && meta.touched ? meta.error : 'Provide details of the legal advice required. This is an unlimited text field, provide as much detail as necessary.'}
                                                    required={true}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    );
                }}
            </Field>
        );
    };

    const documentsPanel = (form: FormApi<InstructMatterFormValues>, submitting: boolean, pristine: boolean, values: InstructMatterFormValues) => {
        return (
            <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>Documents</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12}>
                            <Field name="files" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <DropzoneArea
                                        {...input}
                                        inputProps={{
                                            className: classes.dropZone,
                                        }}
                                        maxFileSize={15000000}
                                        showFileNames={true}
                                        useChipsForPreview={true}
                                        filesLimit={10}
                                        dropzoneText="To add a file, drag and drop or click in this box.
                                        Click the 'X' on a file to delete.
                                        Click the filename to replace it.
                                        View a file using the links above. 
                                        These links will become available once the enquiry is saved."
                                        dropzoneClass={classes.dropZoneFont}
                                        // tslint:disable-next-line: no-shadowed-variable
                                        onChange={(files: File[]) => {
                                            let existingFiles: DocumentFile[] = [...input.value];
                                            const newFiles = files.filter((file: File) => {
                                                return !existingFiles.some((item: DocumentFile) => {
                                                    return item.name === file.name && item.status !== 2;
                                                });
                                            });
                                            if (newFiles && newFiles.length > 0) {
                                                newFiles.forEach((file: File) => {
                                                    const documentFile: DocumentFile = {
                                                        guidID: null,
                                                        file: file,
                                                        status: 1,
                                                        name: file.name,
                                                        url: '',
                                                    };
                                                    existingFiles.push(documentFile);
                                                });
                                                input.onChange(existingFiles);
                                            }
                                        }}
                                        onDelete={(file: File) => {
                                            let existingFiles: DocumentFile[] = [...input.value];
                                            // eslint-disable-next-line array-callback-return
                                            existingFiles.map((item: DocumentFile) => {
                                                if (file.name === item.name) {
                                                    item.file = file;
                                                    item.status = 2; // deleted
                                                }
                                            });
                                            // tslint:disable-next-line: max-line-length
                                            const filteredFiles = existingFiles.filter((item: DocumentFile) => {
                                                return item.status !== 2 || item.guidID !== null;
                                            });
                                            input.onChange(filteredFiles);
                                        }}
                                        // tslint:disable-next-line
                                        onDrop={(files: File[], event: any) => {
                                            // let existingFiles: DocumentFile[] = [...input.value];
                                            // tslint:disable-next-line: max-line-length
                                            // const existingFile = existingFiles.find((item: DocumentFile) => item.name === file)
                                            // tslint:disable-next-line:no-console
                                            console.log(event);
                                        }}
                                        // initialFiles={input.value.map((file: { url: string }) => file.url)}
                                        key={refreshKey}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    };

    const intializeForm = () => {
        if (homeCountryQuery.loading || currencyQuery.loading) {
            return (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            );
        }

        return (
            <Form
                onSubmit={async (values, form: FormApi<InstructMatterFormValues, InstructMatterFormValues>, callback) => await onSubmit(values, form, callback)}
                // mutators={{ ...arrayMutators }}
                initialValues={initialValues}
                validate={onValdiate}
                keepDirtyOnReinitialize={true}
                initialValuesEqual={() => true}
                debug={(values) => {
                    // tslint:disable-next-line: no-console
                    console.log('Debug Values', values);
                }}
                render={({ errors, handleSubmit, form, submitting, pristine, values }) => {
                    if (values.isFormSubmit) {
                        onFieldRequiredError(errors, form, values);
                    }

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className={classes.contentWrapper}>
                                {detailPanel(form, submitting, pristine, values)}
                                {documentsPanel(form, submitting, pristine, values)}
                            </div>
                            {controlButtonComponent(form, submitting, pristine, values)}
                        </form>
                    );
                }}
            />
        );
        
    };
    return (
        <Drawer
            anchor={'right'}
            open={props.open}
            onClose={props.onClose}
            className={classes.root}
            classes={{
                paper: classes.paperRoot,
            }}
        >
            <Typography className={classes.title}>New Matter Instructions</Typography>
            {intializeForm()}
        </Drawer>
    );
};

export default InstructMatterFormDialog;
