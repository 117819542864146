/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IntegrationConfigurationBanner } from './IntegrationConfigurationBanner';
import { RouterProps, showNotification } from '../App';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core';
import InnographyTab from './tabs/InnographyTab';
import InsertLinkOutlinedIcon from '@material-ui/icons/InsertLinkOutlined';
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import VisionSixTab from './tabs/VisionSixTab';
import { client } from '..';
import gql from 'graphql-tag';
import { IntegrationSettingsNamesData, retrieveIntegrationSettingsNamesQueryData, SettingName } from './IntegrationConfigurationRepository';
import { RvLoader } from '../components/Loader';
import ExternalAPIConfigurationTab from './tabs/ExternalAPIConfigurationTab';

const useStyles = makeStyles({
    root: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
    },
    wrapper: {
        flex: 1,
        display: 'flex',
        backgroundColor: '#fff',
        margin: '0 -15px',
        padding: '15px',
    },
    sidebar: {
        padding: '0 15px',
        maxWidth: '400px',
        width: '100%',
        position: 'relative',
        flex: 1,
        display: 'flex',
    },
    sidebarInner: {
        position: 'relative',
        flex: 1,
        // left: 0,
        // right: 0,
        // top: 0,
        // bottom: 0,
        // overflow: 'auto',
    },
    sidebarList: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        overflow: 'auto',
        paddingRight: '10px',
        '& .Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
        '& .Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
    },
    content: {
        padding: '0 15px',
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
        '& > div': {
            flexGrow: 1,
            maxWidth: '700px',
        },
    },
    tabLoaderWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
    },
});

export enum IntegrationConfigurationList {
    InnographyConfiguration = 0,
    Vision6Configuration,
    ExternalAPIConfiguration,
}
export interface SettingNotification {
    description: string;
    id: number;
    message: string;
    status: boolean;
}

interface IntegrationConfigurationPageState {
    selectedTable: IntegrationConfigurationList;
    subscriptionResponse: SettingNotification | null;
    tabs: SettingName[];
    isTabLoading: boolean;
}

interface IntegrationConfigurationPageProps extends RouterProps {}

export default function IntegrationConfigurationPage(props: IntegrationConfigurationPageProps) {
    const classes = useStyles();

    const [pageState, setPageState] = useState<IntegrationConfigurationPageState>({
        selectedTable: IntegrationConfigurationList.InnographyConfiguration,
        subscriptionResponse: null,
        tabs: [],
        isTabLoading: true,
    });

    useEffect(() => {
        fetchTabData();

        var routeTab: IntegrationConfigurationList = IntegrationConfigurationList[props.match.params.tab];

        setPageState({
            ...pageState,
            selectedTable: routeTab ? routeTab : pageState.selectedTable,
        });

        // tslint:disable-next-line: align
    }, []);

    useEffect(() => {
        const subscribeSettingNotificationObj = subscribeSettingNotification();
        // Cleanup
        return () => {
            subscribeSettingNotificationObj.unsubscribe();
        };

        // tslint:disable-next-line: align
    }, []);

    const subscribeSettingNotification = () => {
        return client
            .subscribe({
                fetchPolicy: 'no-cache',
                query: SettingNotificationSubscription,
                variables: {},
            })
            .subscribe({
                // tslint:disable-next-line:no-any
                next(results: any) {
                    setPageState((prevState) => {
                        return {
                            ...prevState,
                            subscriptionResponse: results.data.settingNotification,
                        };
                    });
                    if (results.data.settingNotification.status) {
                        showNotification('Success', results.data.settingNotification.message, 'info');
                    } else {
                        showNotification('Failed', results.data.settingNotification.message, 'error');
                    }
                },
                // tslint:disable-next-line:no-any
                error(err: any) {
                    // tslint:disable-next-line:no-console
                    console.error(err);
                    showNotification(null, err, 'error');
                },
            });
    };

    const fetchTabData = () => {
        retrieveIntegrationSettingsNamesQueryData(
            true,
            // tslint:disable-next-line: no-console
            (data: IntegrationSettingsNamesData) => onIntegrationTabRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            },
        );
    };

    const onIntegrationTabRetrieved = (data: IntegrationSettingsNamesData) => {
        setPageState((prevState) => {
            return {
                ...prevState,
                tabs: data.settings.integrations.settingNames.settingName,
                isTabLoading: false,
            };
        });
    };

    const getIcon = (internalName: string) => {
        if (internalName === IntegrationConfigurationList[IntegrationConfigurationList.InnographyConfiguration]) {
            return (
                <Avatar>
                    <InsertLinkOutlinedIcon />
                </Avatar>
            );
        }

        if (internalName === IntegrationConfigurationList[IntegrationConfigurationList.Vision6Configuration]) {
            return (
                <Avatar>
                    <StoreMallDirectoryOutlinedIcon />
                </Avatar>
            );
        }

        return (
            <Avatar>
                <InsertLinkOutlinedIcon />
            </Avatar>
        );
    };

    const isHasAccess = (internalName: string): boolean => {
        if (internalName === IntegrationConfigurationList[IntegrationConfigurationList.InnographyConfiguration]) {
            return UserFeature.HasAccess(UserFeatureEnum.hasInnographyLink);
        }

        if (internalName === IntegrationConfigurationList[IntegrationConfigurationList.Vision6Configuration]) {
            return UserFeature.HasAccess(UserFeatureEnum.hasVisionSix);
        }

        return true;
    };

    const onClickBack = () => {
        props.history.push('/configurationDashboard');
    };

    const onClickTab = (value: IntegrationConfigurationList) => () => {
        setPageState({
            ...pageState,
            selectedTable: value,
        });

        props.history.push(`/integrationConfiguration/${IntegrationConfigurationList[value]}`);
    };

    return (
        <div className={classes.root}>
            <IntegrationConfigurationBanner title="Integration Configuration" onClickBack={onClickBack} />
            <div className={classes.wrapper}>
                {pageState.isTabLoading ? (
                    <div className={classes.tabLoaderWrapper}>
                        <RvLoader />
                    </div>
                ) : (
                    <>
                        <div className={classes.sidebar}>
                            <div className={classes.sidebarInner}>
                                <List className={classes.sidebarList}>
                                    {pageState.tabs
                                        .map(
                                            (tab: SettingName) =>
                                                isHasAccess(tab.internalName) && (
                                                    <ListItem
                                                        key={tab.guidID}
                                                        button={true}
                                                        onClick={onClickTab(IntegrationConfigurationList[tab.internalName])}
                                                        selected={pageState.selectedTable === IntegrationConfigurationList[tab.internalName]}
                                                    >
                                                        <ListItemAvatar>{getIcon(tab.internalName)}</ListItemAvatar>
                                                        <ListItemText primary={tab.title} secondary={tab.description} />
                                                    </ListItem>
                                                ),
                                        )}
                                </List>
                            </div>
                        </div>
                        <div className={classes.content}>
                            {pageState.selectedTable === IntegrationConfigurationList.InnographyConfiguration && UserFeature.HasAccess(UserFeatureEnum.hasInnographyLink) && (
                                <InnographyTab subscriptionResponse={pageState.subscriptionResponse} />
                            )}
                            {pageState.selectedTable === IntegrationConfigurationList.Vision6Configuration && UserFeature.HasAccess(UserFeatureEnum.hasVisionSix) && (
                                <VisionSixTab subscriptionResponse={pageState.subscriptionResponse} />
                            )}
                            {pageState.selectedTable === IntegrationConfigurationList.ExternalAPIConfiguration && (
                                <ExternalAPIConfigurationTab subscriptionResponse={pageState.subscriptionResponse} />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

const SettingNotificationSubscription = gql`
    subscription SettingfigNotification {
        settingNotification {
            description
            id
            message
            status
        }
    }
`;
