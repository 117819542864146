import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '..';

export interface IntegrationSettingsNamesData {
    settings: Settings;
    loading?: boolean;
    networkStatus?: number;
}

export interface Settings {
    integrations: Integrations;
}

export interface Integrations {
    settingNames: SettingNames;
}

export interface SettingNames {
    settingName: SettingName[];
}

export interface SettingName {
    id:           number;
    guidID:       string;
    title:        string;
    description:  string;
    internalName: string;
}

export function retrieveIntegrationSettingsNamesQueryData(
    refreshData: boolean,
    onSuccess: (data: IntegrationSettingsNamesData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

        client.query({
            query: IntegrationSettingsNamesQuery,
            variables: {},
            fetchPolicy: fetchPolicy
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any ) => { onError(reason); });
}

export const IntegrationSettingsNamesQuery = gql`
    query IntegrationSettingsNames{
        settings {
            id,
            integrations {
                id,
                settingNames {
                    settingName {
                        id,
                        guidID,
                        title,
                        description,
                        internalName
                    }
                }
            }
        }
    }
`;