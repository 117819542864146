import { serverUrl } from '../App';
import { createUploadLink } from 'apollo-upload-client';
import { isPlainObject } from 'lodash';
import { ApolloLink, Operation, NextLink } from 'apollo-link';

const GRAPHQL_ENDPOINT = serverUrl + '/api/graphql';

const uploadLink = createUploadLink({
    uri: GRAPHQL_ENDPOINT
});

const responseLink = new ApolloLink(
    (operation: Operation, forward: NextLink) => {
        return forward(operation).map(data => {
            // for some reason, upload link returns array, 
            // and we always expect the object
            if (Array.isArray(data) && data.length > 0) {
                return data[0];
            }
            return data;
        });
    }
);

export const multiPartLink =  responseLink.concat(uploadLink);

// tslint:disable-next-line:no-any
const isFileObject = (value: any): boolean => {
    if (isPlainObject(value)) {
        return Object.values(value).map(isFileObject).includes(true);
    }
    if (value instanceof Array) {
        let instanceOfFile = false;
        for (let i = 0; i < value.length; ++i) {
            instanceOfFile = isInstanceOfFile(value[i]);
            if (instanceOfFile === true) {
                break; 
            }
            instanceOfFile = isPropertyInstanceOfFile(value[i]);
            if (instanceOfFile === true) {
                break; 
            }
        }        
        return instanceOfFile;    
    } else {
        let instanceOfFile = isInstanceOfFile(value);
        if (instanceOfFile === false) {
            instanceOfFile = isPropertyInstanceOfFile(value);
        }
        return instanceOfFile;
    }    
};

// tslint:disable-next-line:no-any
const isPropertyInstanceOfFile = (value: any): boolean => {
    let instanceOfFile = false;
    if (value !== undefined && value !== null && value.hasOwnProperty('file')) {
        instanceOfFile = isInstanceOfFile(value.file);
    }
    return instanceOfFile;
};

// tslint:disable-next-line:no-any
const isInstanceOfFile = (value: any): boolean => {
    const isfile = typeof File !== 'undefined' && value instanceof File;
    const isblob = typeof Blob !== 'undefined' && value instanceof Blob;
    return isfile || isblob;
};

// tslint:disable-next-line:no-any
export const isUploadRequest = (variables: any) => {
    return Object.values(variables).some(isFileObject);
};