import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin: ReactPlugin = new ReactPlugin();
// property is fetched from "config/webpack.config.{env}.js" file, under plugins section
declare const AppInsightsInstrumentationKey: string;

// tslint:disable-next-line:no-any
export const appInsights: any = new ApplicationInsights({
    config: {
        instrumentationKey: AppInsightsInstrumentationKey,
        extensions: [reactPlugin],
        samplingPercentage: 100       
    }
});
appInsights.loadAppInsights();