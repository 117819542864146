import { Button, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RvLoader } from '../../components/Loader';
import gql from 'graphql-tag';
import { SettingNotification } from '../IntegrationConfigurationPage';
import { useMutation, useQuery } from 'react-apollo';
import { v4 } from 'uuid';
import { showNotification } from '../../App';
import { client } from '../..';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: 30,
        },
        textField: {
            width: '100%'
        },
        textFieldWithButton: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            margin: '0 -10px',
            '& > div': {
                margin: '0 10px',
            },
            '& > button': {
                margin: '0 10px',
            }
        },
    })
);

interface ExternalAPIConfigurationTabProps {
    subscriptionResponse: SettingNotification | null;
}
interface ExternalAPIConfigurationTabState {
    isLoading: boolean;
    clientKey: string;
    clientSecret: string;
}

export default function ExternalAPIConfigurationTab(props: ExternalAPIConfigurationTabProps) {

    const classes = useStyles();

    const [state, setState] = useState<ExternalAPIConfigurationTabState>({
        isLoading: false,
        clientKey: '',
        clientSecret: ''
    });

    const clientData = useQuery<ClientDataQuery>(ClientDataQuery);

    // tslint:disable-next-line: no-any
    const [createApiUser] = useMutation<any, CreateApiUserMutationParams>(CreateApiUser);

    useEffect(() => {    
        // tslint:disable-next-line: max-line-length
        if (!clientData.loading && clientData.data) {
            var { 
                clientSecret,
                clientKey
            } = clientData.data.administrator.clientData;            

            // tslint:disable-next-line: no-any
            setState((prevState: any) => {
                return {
                    ...prevState,
                    isLoading: false,
                    clientKey: clientKey != null ? clientKey : '',
                    clientSecret: clientSecret != null ? clientSecret : ''
                };
            });
        }
    // tslint:disable-next-line: align
    }, [clientData.data, clientData.loading]);

    useEffect(() => {
        const subscribeSettingNotificationObj = subscribeApiNotification();
        // Cleanup
        return () => {
            subscribeSettingNotificationObj.unsubscribe();
        };

        // tslint:disable-next-line: align
    }, []);

    const subscribeApiNotification = () => {
        return client
            .subscribe({
                fetchPolicy: 'no-cache',
                query: ApiSubscription,
                variables: {},
            })
            .subscribe({
                // tslint:disable-next-line:no-any
                next(results: any) {

                    if (results.data.createExternalApiAccessNotification.status) {
                        showNotification('Success', results.data.createExternalApiAccessNotification.message, 'info');
                    } else {
                        showNotification('Failed', results.data.createExternalApiAccessNotification.message, 'error');
                    }
                },
                // tslint:disable-next-line:no-any
                error(err: any) {
                    // tslint:disable-next-line:no-console
                    console.error(err);
                    showNotification(null, err, 'error');
                },
            });
    };

    const onSubmit = () => {        
        createApiUser({
            variables: {
                clientKey: state.clientKey ? state.clientKey : '',
                clientSecret: state.clientSecret ? state.clientSecret : ''
            }
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                showNotification(null, 'Successfully submitted', 'info');     
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to create ApiUser', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };
    
    return (
        <div className={classes.root}>
           <Grid container={true} spacing={3}>
                {state.isLoading ? (
                    <Grid item={true} xs={12} md={12} justify="center">
                        <RvLoader />
                    </Grid>
                ) : (
                    <>
                        <Grid item={true} xs={12} md={12}>
                            <div className={classes.textFieldWithButton}>
                                <TextField 
                                    label="Client Key" 
                                    required={true}
                                    className={classes.textField}
                                    value={state.clientKey}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                                <Button 
                                    variant="outlined" 
                                    color="primary"
                                    onClick={() => {
                                        // tslint:disable-next-line: no-any
                                        setState((prevState: any) => {
                                            return {
                                                ...prevState,
                                                clientKey: v4(),
                                            };
                                        });
                                    }}
                                >
                                    Generate
                                </Button>
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} md={12}>
                            <div className={classes.textFieldWithButton}>
                                <TextField 
                                    label="Client Secret" 
                                    required={true}
                                    className={classes.textField}
                                    value={state.clientSecret}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                                <Button 
                                    variant="outlined" 
                                    color="primary"
                                    onClick={() => {
                                        // tslint:disable-next-line: no-any
                                        setState((prevState: any) => {
                                            return {
                                                ...prevState,
                                                clientSecret: v4(),
                                            };
                                        });
                                    }}
                                >
                                    Generate
                                </Button>
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} md={12} justify="flex-end">
                            <Button 
                                variant="outlined" 
                                color="primary"
                                onClick={onSubmit} 
                                disabled={!state.clientSecret || !state.clientKey}                               
                            >
                                Save
                            </Button>
                        </Grid>
                    </>
                )}
           </Grid>
        </div>
    );
}

interface ClientDataQuery {
    administrator: ClientData;
}

interface ClientData {
    clientData: ClientDataResult;
}
interface ClientDataResult {
    clientSecret: string | null;
    clientKey: string | null;
}
 
const ClientDataQuery = gql`
    query ClientDataQuery{
        administrator {
            clientData {
                clientSecret
                clientKey
            }
        }
    }
`;

interface CreateApiUserMutationParams {
    clientSecret: string | null;
    clientKey: string | null;
}

const CreateApiUser = gql`
    mutation createApiUser (
        $clientSecret: String,
        $clientKey: String) {
        integrations {
            aPIUser {
                update(clientKey: $clientKey, clientSecret: $clientSecret) {
                    status
                }
            }
        }
    }
`;

const ApiSubscription = gql`
subscription createExternalApiAccessNotification{
    createExternalApiAccessNotification{
       id
        message
        status
        description
    }
  }
`;
