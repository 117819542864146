/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, CircularProgress, createStyles, FormControlLabel, Grid, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ContactListSelector } from '../selectors/ContactListSelector';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '../..';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { SettingNotification } from '../IntegrationConfigurationPage';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: 30,
        },
        apiWrapper: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            margin: '0 -10px',
            '& > div': {
                margin: '0 10px',
            },
            '& > button': {
                margin: '0 10px',
            }
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        icon: {
            color: 'green',
        }
    })
);

interface VisionSixTabProps {
    subscriptionResponse: SettingNotification | null;
}

interface VisionSixTabState {
    isEnabled: boolean;
    isSendEmailViaVision6: boolean;
    contactList: IAutoCompleteItem | null;
    isValidAPI: boolean;
    isLoading: boolean;
    isValidAPILoading: boolean;
    apiKey: string;
}

export default function VisionSixTab(props: VisionSixTabProps) {

    const classes = useStyles();

    const [state, setState] = useState<VisionSixTabState>({
        isEnabled: false,
        isSendEmailViaVision6: false,
        contactList: null,
        isValidAPI: true,
        isLoading: true,
        apiKey: '',
        isValidAPILoading: false
    });

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    useEffect(() => {

        if (props.subscriptionResponse && props.subscriptionResponse.message.includes('authenticated successfully')) {
            setState((_prevState) => {
                return {
                    ..._prevState,
                    isValidAPI: true,
                    isValidAPILoading: false
                };
            });
        } 
        if (props.subscriptionResponse && props.subscriptionResponse.message.includes('authentication failed')) {
            setState((_prevState) => {
                return {
                    ..._prevState,
                    isValidAPI: false,
                    isValidAPILoading: false
                };
            });
        }
    // tslint:disable-next-line: align
    }, [props.subscriptionResponse]);
    
    const fetchData = () => {
        retrieveVision6ConfigQueryData(
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveData = (data: Vision6ConfigData) => {
        setState((prevState) => {
            return {
                ...prevState,
                isEnabled: data.settings.integrations.vision6.config.isEnabled,
                isSendEmailViaVision6: data.settings.integrations.vision6.config.sendEmailsViaVision6,
                apiKey: data.settings.integrations.vision6.config.token,
                // tslint:disable-next-line: max-line-length
                contactList: data.settings.integrations.vision6.config.contactListName 
                             && data.settings.integrations.vision6.config.contactListId 
                             ?  { 
                                    label: data.settings.integrations.vision6.config.contactListName, 
                                    value: data.settings.integrations.vision6.config.contactListId 
                                }
                             : null,
                isLoading: false
            };
        });
    };

    // tslint:disable-next-line: no-any
    const onTextFieldChange = (event: any) => {
        setState({
            ...state,
            apiKey: event.target.value,
            isValidAPI: false
        });
    };

    const onCheckboxChange = (name: string) => (event: React.ChangeEvent<{}>, checked: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                [name]: checked
            };
        });
    };

    const onSelection = (value: IAutoCompleteItem, name?: string) => {
        setState((prevState) => {
            return {
                ...prevState,
                contactList: value
            };
        });
    };

    const onValidateAPiKey = () => {
        if (state.apiKey) {

            setState((_prevState) => {
                return {
                    ..._prevState,
                    isValidAPILoading: true
                };
            });

            client
                .mutate({
                    mutation: AuthenticateVision6Mutation,
                    variables: {
                        token: state.apiKey
                    },
                })
                // tslint:disable-next-line: no-any
                .then((results: { data: any }) => {
                    if (results.data) {
                        if (results.data.integrations.vision6.authenticate.status && results.data.integrations.vision6.authenticate.error === null) {
                            showNotification(null, 'Successfully submitted', 'info');     
                        }
                    }
                })
                // tslint:disable-next-line:no-any
                .catch((reason: any) => {
                    showNotification('Failed to Innography', reason, 'error');
                    setState((_prevState) => {
                        return {
                            ..._prevState,
                            isLoading: false
                        };
                    });
                });
        }
    };

    const onSaveConfig = () => {
        client
            .mutate({
                mutation: UpdateVision6ConfigMutation,
                variables: {
                    token: state.apiKey, 
                    contactListId: state.contactList ? state.contactList.value : null,
                    contactListName: state.contactList ? state.contactList.label : null,
                    isEnabled: state.isEnabled,
                    sendEmailsViaVision6: state.isSendEmailViaVision6
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.integrations.vision6.update.status && results.data.integrations.vision6.update.error === null) {
                        showNotification(null, 'Successfully submitted', 'info');     
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Vision 6', reason, 'error');
                setState((_prevState) => {
                    return {
                        ..._prevState,
                        isLoading: false
                    };
                });
            });
    };

    const validateIndicator = () => {
        if (state.isValidAPILoading) {
            return (
                <InputAdornment position="start">
                    <CircularProgress size={14} />
                </InputAdornment>
            );
        }
        if (state.isValidAPI) {
            return (
                <InputAdornment position="start">
                    <CheckCircleIcon className={classes.icon} />
                </InputAdornment>
            );
        }
        return undefined;
    };
    
    return (
        <div className={classes.root}>
           <Grid container={true} spacing={3}>
                {state.isLoading ? (
                    <Grid item={true} xs={12} md={12} justify="center">
                        <RvLoader />
                    </Grid>
                ) : (
                    <>
                        <Grid item={true} xs={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checkedIcon={<CheckBoxIcon color="primary" />} 
                                        checked={state.isEnabled} 
                                    />
                                }
                                name="isEnabled" 
                                onChange={onCheckboxChange('isEnabled')} 
                                label="Enabled"
                            />
                        </Grid>
                        <Grid item={true} xs={12} md={12}>
                            <div className={classes.apiWrapper}>
                                <TextField 
                                    label="API key" 
                                    required={true}
                                    className={classes.textField}
                                    disabled={!state.isEnabled}
                                    onChange={onTextFieldChange}
                                    value={state.apiKey}
                                    InputProps={{
                                        endAdornment: validateIndicator()
                                    }}
                                />
                                <Button 
                                    variant="outlined" 
                                    color="primary"
                                    disabled={!state.isEnabled || state.isValidAPI}
                                    onClick={onValidateAPiKey}
                                >
                                    Validate
                                </Button>
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checkedIcon={<CheckBoxIcon color="primary" />} 
                                        checked={state.isSendEmailViaVision6} 
                                    />
                                }
                                onChange={onCheckboxChange('isSendEmailViaVision6')} 
                                name="isSendEmailViaVision6" 
                                disabled={!state.isEnabled}
                                label="Send emails via vision 6"
                            />
                        </Grid>
                        <Grid item={true} xs={12} md={12}>
                            <ContactListSelector 
                                label="Contact Lst" 
                                required={true}
                                className={classes.textField}
                                disabled={!state.isEnabled || !state.isValidAPI}
                                value={state.contactList}
                                onSelection={onSelection}
                                refresh={state.isValidAPI}
                            />
                        </Grid>
                        <Grid item={true} xs={12} md={12}>
                            <div className={classes.buttonWrapper}>
                                <Button 
                                    variant="outlined" 
                                    color="primary"
                                    onClick={onSaveConfig}
                                    disabled={state.contactList === null || !state.isValidAPI}
                                >
                                    Save
                                </Button>
                                <Button variant="outlined" color="primary">
                                    Reset
                                </Button>
                            </div>
                        </Grid>
                    </>
                )}
           </Grid>
        </div>
    );
}

export interface Vision6ConfigData {
    settings: Settings;    
}

export interface Settings {
    integrations: Integrations;
}

export interface Integrations {
    vision6: Vision6;
}

export interface Vision6 {
    config: Config;
}

export interface Config {
    contactListId: string;
    contactListName: string;
    isEnabled:     boolean;
    sendEmailsViaVision6: boolean;
    token:         string;
}

export function retrieveVision6ConfigQueryData(
    refreshData: boolean,
    onSuccess: (data: Vision6ConfigData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: Vision6ConfigQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const Vision6ConfigQuery = gql`
query Vision6ConfigQuery{
    settings{
        id,
        integrations {
            id,
            vision6 {
                id,
                config {
                    id,
                    isEnabled,
                    sendEmailsViaVision6,
                    token,
                    contactListId,
                    contactListName
                }        
            }
        }
    }
}`;

const AuthenticateVision6Mutation = gql`
    mutation AuthenticateVision6Mutation($token: String) {
        integrations {
            vision6 {
                authenticate(token: $token) {
                    error
                    status
                }
            }
        }
    }
`;

const UpdateVision6ConfigMutation = gql`
    mutation UpdateVision6ConfigMutation(
        $token: String, 
        $contactListId: String, 
        $isEnabled: Boolean, 
        $sendEmailsViaVision6: Boolean,
        $contactListName: String
    ) {
        integrations {
            vision6 {
                update(
                    token: $token, 
                    contactListId: $contactListId, 
                    contactListName: $contactListName,
                    isEnabled: $isEnabled, 
                    sendEmailsViaVision6: $sendEmailsViaVision6
                ) {
                    error
                    status
                }
            }
        }
    }
`;