export interface TranslationTextList {
    [key: string]: string;
    Help: string;
    ScrubEnquiryData: string;
    LeadDashboardLayout: string;
    EnquiryDashboardLayout: string;
    FirmDashboardLayout: string;
    Tasks: string;
    PhoneCalls: string;
    TasksFound: string;
    LoginActivity: string;
    NameAccess: string;
    Configuration: string;
    Home: string;
    Admin: string;
    GeneralDetails: string;
    GuidId: string;
    Billed: string;
    BilledInxTax: string;
    BilledFees: string;
    PercentageEstimate: string;
    PercentageExposure: string;
    PercentageEstimateShort: string;
    Financials: string;
    MatterId: string;
    FileNumber: string;
    MatterNumber: string;
    Logo: string;
    Reference: string;
    ClientName: string;
    Client: string;
    ClientPhone: string;
    ClientEmail: string;
    Clients: string;
    Status: string;
    LastUpdate: string;
    PublishedDate: string;
    InternalCode: string;
    FileLocation: string;
    Matter: string;
    Matters: string;
    StaffBillingSummary: string;
    MattersReferred: string;
    MatterType: string;
    MatterTypeCategory: string;
    EstimatedCosts: string;
    BillingMethod: string;
    CostAgreementReceived: string;
    LongTitle: string;
    Completed: string;
    Title: string;
    Description: string;
    State: string;
    FinancialBilled: string;
    TrustFund: string;
    TrustBalance: string;
    BilledTimeAndFees: string;
    BilledCostAndPayments: string;
    Unbilled: string;
    UnbilledShort: string;
    UnbilledFees: string;
    UnbilledDisbursements: string;
    UnbilledDisbursementsShort: string;
    Price: string;
    Deposit: string;
    ExchangeDate: string;
    CoolingOffDate: string;
    DepositDueDate: string;
    DepositPaidDate: string;
    EarliestPriorityDate: string;
    ApplicationNumber: string;
    PublicationDate: string;
    PublicationNumber: string;
    GrantNumber: string;
    Class: string;
    GoodsAndServices: string;
    CostAgreementReceivedDate: string;
    CostAgreementRequired: string;
    UnbilledTimeAndFees: string;
    Disbursements: string;
    UnbilledCostRecoveries: string;
    UnbilledOfficePayments: string;
    UnbilledAnticipatedPayments: string;
    TrustGeneralBalance: string;
    DebtorsBalance: string;
    CurrentDebtorsBalanceIncTax: string;
    ThirtyDebtorsBalanceIncTax: string;
    SixtyDebtorsBalanceIncTax: string;
    NintyDebtorsBalanceIncTax: string;
    CurrentWIPBalanceExTax: string;
    ThirtyWIPBalanceExTax: string;
    SixtyWIPBalanceExTax: string;
    NinetyWIPBalanceExTax: string;
    Trust: string;
    TrustDeposits: string;
    TrustWithdrawal: string;
    TrustControlledMoneyBalance: string;
    TrustInvestmentsBalance: string;
    Role: string;
    ABN: string;
    ACN: string;
    OrganisationType: string;
    BusinessType: string;
    OtherSide: string;
    CategoryId: string;
    Category: string;
    CountryId: string;
    Country: string;
    ClientOnly: string;
    Property: string;
    PropertyType: string;
    PropertyLabel: string;
    Family: string;
    BrandFamily: string;
    Stage: string;
    MatterNotes: string;
    AwaitingInstructions: string;
    FIRBStatus: string;
    Documents: string;
    SearchDocument: string;
    Messages: string;
    SearchMessages: string;
    Timeline: string;
    SearchTimeline: string;
    Overdue: string;
    DueComing: string;
    Today: string;
    CompletedOnly: string;
    UploadedOn: string;
    CreatedOn: string;
    By: string;
    Notes: string;
    Download: string;
    SummaryList: string;
    Summary: string;
    UserList: string;
    LeadsSummary: string;
    Leads: string;
    EnquiriesSummary: string;
    Enquiries: string;
    KeyOpportunity: string;
    Enquiry: string;
    EnquiryDetails: string;
    MyEnquiries: string;
    MyKeyOpportunities: string;
    SearchNewEnquiries: string;
    SearchNewKeyOpportunity: string;
    MyNewEnquiries: string;
    MyNewKeyOpportunity: string;
    EnquiriesDashboard: string;
    LeadsDashboard: string;
    User: string;
    RoleSummary: string;
    Roles: string;
    MatterAccess: string;
    MessageActivity: string;
    TimesheetActivity: string;
    TimelineAnalytics: string;
    MatterAnalytics: string;
    NameAnalytics: string;
    LoginAnalytics: string;
    SearchEntries: string;
    SearchMatters: string;
    SearchStaffBudget: string;
    SearchClientsContacts: string;
    SearchTasks: string;
    IncludeCompleted: string;
    IncludeDead: string;
    IncludeInactive: string;
    Search: string;
    Email: string;
    Phone: string;
    Address: string;
    Staff: string;
    Scope: string;
    Recipient: string;
    RoleCategory: string;
    Reports: string;
    ReportsCatalog: string;
    OurFirm: string;
    NewMessage: string;
    NewNote: string;
    TypeNote: string;
    TypeMessage: string;
    SendMessageAction: string;
    Send: string;
    Cancel: string;
    Dashboard: string;
    DashboardLayout: string;
    FirmDashboard: string;
    TaskDashboard: string;
    ClientDashboard: string;
    FeeEarnerDashboard: string;
    FeeEarnerDashboardLayout: string;
    TaskDashboardLayout: string;
    ClientDashboardLayout: string;
    MattersSummary: string;
    MatterManagement: string;
    Logout: string;
    ChangePassword: string;
    CurrentPassword: string;
    NewPassword: string;
    ConfirmPassword: string;
    Save: string;
    SupportedBrowsers: string;
    MemberLogin: string;
    Username: string;
    Password: string;
    RememberMe: string;
    ForgottenPassword: string;
    Login: string;
    GoogleChrome: string;
    Safari: string;
    MozillaFirefox: string;
    MicrosoftEdge: string;
    InternetExplorer: string;
    NoDocumentsAvailable: string;
    NoMessage: string;
    Subject: string;
    InternalMessage: string;
    Party: string;
    Parties: string;
    Due: string;
    NoTimelineDatesSet: string;
    MatterTitle: string;
    General: string;
    MatterCategory: string;
    TypeId: string;
    Type: string;
    TypeClassification: string;
    TypeState: string;
    SubTypeID: string;
    SubType: string;
    LastComment: string;
    Last3Comments: string;
    LastMessage: string;
    Last3Messages: string;
    SettlementDate: string;
    SyncSystemID: string;
    ResponsibleID: string;
    Responsible: string;
    ResponsibleShort: string;
    ResponsibleEmail: string;
    ResponsiblePhone: string;
    ActingID: string;
    Acting: string;
    ActingEmail: string;
    ActingPhone: string;
    AssistingID: string;
    Assisting: string;
    AssistingEmail: string;
    AssistingPhone: string;
    CompletionDate: string;
    InstructionDate: string;
    InProgress: string;
    IsVip: string;
    FIRB: string;
    SplashTemplates: string;
    UserDefinedHelp: string;
    UserDashboard: string;
    FeatureAccess: string;
    FeatureLicencing: string;
    RoleAccesss: string;
    RoleSecurity: string;
    ClientReference: string;
    Owner: string;
    Debtors: string;
    NextRenewalDate: string;
    NextDue: string;
    NextActionDate: string;
    NextAction: string;
    NextActionItem: string;
    Refresh: string;
    ExportFile: string;
    SaveLayout: string;
    ConfigureViews: string;
    Print: string;
    ViewConfiguration: string;
    ViewName: string;
    AvailableRole: string;
    ForSecurity: string;
    SharedView: string;
    Delete: string;
    Settings: string;
    AddNew: string;
    EditGroup: string;
    FilterColumn: string;
    GeneralInformation: string;
    Referrals: string;
    Vendor: string;
    Council: string;
    ContactFirm: string;
    InternalResponsibilities: string;
    Date: string;
    RequestNewPassword: string;
    MultifactorAuthenticationMessage: string;
    AuthenticationCode: string;
    Verify: string;
    VerificationFailed:
        'Verification code is incorrect. Please specify the correct code and try again';
    VerificationCodeResendSuccessMessage: string;
    ResendVerificationCode: string;
    MatterSummaryConfigKey: string;
    MatterSummaryConfigTemplateName: string;
    MatterSummaryConfigId: string;
    MatterSummaryConfigIsUserLevel: string;
    MatterSummaryConfigIsRoleLevel: string;
    MatterSummaryConfigIsTenantLevel: string;
    MyDefault: string;
    TenantDefault: string;
    FirmDefault: string;
    MatterSummaryConfigIsSecurity: string;
    LeapSignIn: string;
    MyViewOnly: string;
    RoleColumnRoleId: string;
    RoleColumnRoleName: string;
    RoleColumnSelected: string;
    RoleColumnIsDefault: string;
    LeapReset: string;
    Name: string;
    NameSummary: string;
    StaffSummary: string;
    Names: string;
    ClientsAndContacts: string;
    Referrer: string;
    Referrers: string;
    TotalOwing: string;
    TimeslipSummary: string;
    AttorneyDashboard: string;
    Timesheet: string;
    Timelines: string;
    Innography: string;
    ClientSense: string;
    MailingList: string;
    InvoiceSummary: string;
    ReportingGroup: string;
    ArchivedDate: string;
    TrustAccountBalance: string;
    ReviewDate: string;

    // deb details
    OriginalDebt: string;
    CollectionCosts: string;
    LegalCostsRecoverable: string;
    LegalCostsNonRecoverable: string;
    TotalDisbursements: string;
    TotalInterest: string;
    TotalPayments: string;
    TotalOutstanding: string;
    EventsAndCampaigns: string;

    PriorityCountry: string;
    GeneralMaterial: string;
    InjuryDate: string;
    ProceedingsIssuedDate: string;
    SunsetOrLimitationDate: string;
    HearingDate: string;
    ClientDocuments: string;
    EnquiryQuality: string;
    NewEnquiry: string;
    Organisation: string;
    Trustee: string;
    TrusteeEmail: string;
}

const countryToLanguage = {
    'AD': 'ca-AD',
    'AE': 'ar-AE',
    'AF': 'fa-AF',
    'AG': 'en-AG',
    'AI': 'en-AI',
    'AL': 'sq-AL',
    'AM': 'hy-AM',
    'AO': 'pt-AO',
    'AQ': 'und-AQ',
    'AR': 'es-AR',
    'AS': 'en-AS',
    'AT': 'de-AT',
    'AU': 'en-AU',
    'AW': 'nl-AW',
    'AX': 'sv-AX',
    'AZ': 'az-Latn-AZ',
    'BA': 'bs-BA',
    'BB': 'en-BB',
    'BD': 'bn-BD',
    'BE': 'nl-BE',
    'BF': 'fr-BF',
    'BG': 'bg-BG',
    'BH': 'ar-BH',
    'BI': 'rn-BI',
    'BJ': 'fr-BJ',
    'BL': 'fr-BL',
    'BM': 'en-BM',
    'BN': 'ms-BN',
    'BO': 'es-BO',
    'BQ': 'nl-BQ',
    'BR': 'pt-BR',
    'BS': 'en-BS',
    'BT': 'dz-BT',
    'BV': 'und-BV',
    'BW': 'en-BW',
    'BY': 'be-BY',
    'BZ': 'en-BZ',
    'CA': 'en-CA',
    'CC': 'ms-CC',
    'CD': 'sw-CD',
    'CF': 'fr-CF',
    'CG': 'fr-CG',
    'CH': 'de-CH',
    'CI': 'fr-CI',
    'CK': 'en-CK',
    'CL': 'es-CL',
    'CM': 'fr-CM',
    'CN': 'zh-CN',
    'CO': 'es-CO',
    'CR': 'es-CR',
    'CU': 'es-CU',
    'CV': 'pt-CV',
    'CW': 'pap-CW',
    'CX': 'en-CX',
    'CY': 'el-CY',
    'CZ': 'cs-CZ',
    'DE': 'de-DE',
    'DJ': 'aa-DJ',
    'DK': 'da-DK',
    'DM': 'en-DM',
    'DO': 'es-DO',
    'DZ': 'ar-DZ',
    'EC': 'es-EC',
    'EE': 'et-EE',
    'EG': 'ar-EG',
    'EH': 'ar-EH',
    'ER': 'ti-ER',
    'ES': 'es-ES',
    'ET': 'am-ET',
    'FI': 'fi-FI',
    'FJ': 'en-FJ',
    'FK': 'en-FK',
    'FM': 'chk-FM',
    'FO': 'fo-FO',
    'FR': 'fr-FR',
    'GA': 'fr-GA',
    'GB': 'en-GB',
    'GD': 'en-GD',
    'GE': 'ka-GE',
    'GF': 'fr-GF',
    'GG': 'en-GG',
    'GH': 'ak-GH',
    'GI': 'en-GI',
    'GL': 'kl-GL',
    'GM': 'en-GM',
    'GN': 'fr-GN',
    'GP': 'fr-GP',
    'GQ': 'fan-GQ',
    'GR': 'el-GR',
    'GS': 'und-GS',
    'GT': 'es-GT',
    'GU': 'en-GU',
    'GW': 'pt-GW',
    'GY': 'en-GY',
    'HK': 'zh-HK',
    'HM': 'und-HM',
    'HN': 'es-HN',
    'HR': 'hr-HR',
    'HT': 'ht-HT',
    'HU': 'hu-HU',
    'ID': 'id-ID',
    'IE': 'en-IE',
    'IL': 'he-IL',
    'IM': 'en-IM',
    'IN': 'hi-IN',
    'IO': 'und-IO',
    'IQ': 'ar-IQ',
    'IR': 'fa-IR',
    'IS': 'is-IS',
    'IT': 'it-IT',
    'JE': 'en-JE',
    'JM': 'en-JM',
    'JO': 'ar-JO',
    'JP': 'ja-JP',
    'KE': 'sw-KE',
    'KG': 'ky-KG',
    'KH': 'km-KH',
    'KI': 'en-KI',
    'KM': 'ar-KM',
    'KN': 'en-KN',
    'KP': 'ko-KP',
    'KR': 'ko-KR',
    'KW': 'ar-KW',
    'KY': 'en-KY',
    'KZ': 'kk-KZ',
    'LA': 'lo-LA',
    'LB': 'ar-LB',
    'LC': 'en-LC',
    'LI': 'de-LI',
    'LK': 'si-LK',
    'LR': 'en-LR',
    'LS': 'st-LS',
    'LT': 'lt-LT',
    'LU': 'fr-LU',
    'LV': 'lv-LV',
    'LY': 'ar-LY',
    'MA': 'ar-MA',
    'MC': 'fr-MC',
    'MD': 'ro-MD',
    'ME': 'sr-Latn-ME',
    'MF': 'fr-MF',
    'MG': 'mg-MG',
    'MH': 'mh-MH',
    'MK': 'mk-MK',
    'ML': 'bm-ML',
    'MM': 'my-MM',
    'MN': 'mn-Cyrl-MN',
    'MO': 'zh-MO',
    'MP': 'en-MP',
    'MQ': 'fr-MQ',
    'MR': 'ar-MR',
    'MS': 'en-MS',
    'MT': 'mt-MT',
    'MU': 'mfe-MU',
    'MV': 'dv-MV',
    'MW': 'ny-MW',
    'MX': 'es-MX',
    'MY': 'ms-MY',
    'MZ': 'pt-MZ',
    'NA': 'af-NA',
    'NC': 'fr-NC',
    'NE': 'ha-NE',
    'NF': 'en-NF',
    'NG': 'en-NG',
    'NI': 'es-NI',
    'NL': 'nl-NL',
    'NO': 'nb-NO',
  'NP': 'ne-NP',
  'NR': 'en-NR',
  'NU': 'niu-NU',
  'NZ': 'en-NZ',
  'OM': 'ar-OM',
  'PA': 'es-PA',
  'PE': 'es-PE',
  'PF': 'fr-PF',
  'PG': 'tpi-PG',
  'PH': 'fil-PH',
  'PK': 'ur-PK',
  'PL': 'pl-PL',
  'PM': 'fr-PM',
  'PN': 'en-PN',
  'PR': 'es-PR',
  'PS': 'ar-PS',
  'PT': 'pt-PT',
  'PW': 'pau-PW',
  'PY': 'gn-PY',
  'QA': 'ar-QA',
  'RE': 'fr-RE',
  'RO': 'ro-RO',
  'RS': 'sr-Latn-RS',
  'RU': 'ru-RU',
  'RW': 'rw-RW',
  'SA': 'ar-SA',
  'SB': 'en-SB',
  'SC': 'crs-SC',
  'SD': 'ar-SD',
  'SE': 'sv-SE',
  'SG': 'zh-SG',
  'SH': 'en-SH',
  'SI': 'sl-SI',
  'SJ': 'nb-SJ',
  'SK': 'sk-SK',
  'SL': 'kri-SL',
  'SM': 'it-SM',
  'SN': 'fr-SN',
  'SO': 'sw-SO',
  'SR': 'srn-SR',
  'SS': 'ar-SS',
  'ST': 'pt-ST',
  'SV': 'es-SV',
  'SX': 'nl-SX',
  'SY': 'ar-SY',
  'SZ': 'ss-SZ',
  'TC': 'en-TC',
  'TD': 'fr-TD',
  'TF': 'und-TF',
  'TG': 'fr-TG',
  'TH': 'th-TH',
  'TJ': 'tg-Cyrl-TJ',
  'TK': 'tkl-TK',
  'TL': 'pt-TL',
  'TM': 'tk-TM',
  'TN': 'ar-TN',
  'TO': 'to-TO',
  'TR': 'tr-TR',
  'TT': 'en-TT',
  'TV': 'tvl-TV',
  'TW': 'zh-TW',
  'TZ': 'sw-TZ',
  'UA': 'uk-UA',
  'UG': 'sw-UG',
  'UM': 'en-UM',
  'US': 'en-US',
  'UY': 'es-UY',
  'UZ': 'uz-Latn-UZ',
  'VA': 'la-VA',
  'VC': 'en-VC',
  'VE': 'es-VE',
  'VG': 'en-VG',
  'VI': 'en-VI',
  'VN': 'vi-VN',
  'VU': 'bi-VU',
  'WF': 'fr-WF',
  'WS': 'sm-WS',
  'XK': 'sq-XK',
  'YE': 'ar-YE',
  'YT': 'swb-YT',
  'ZA': 'en-ZA',
  'ZM': 'en-ZM',
  'ZW': 'en-ZW',
} as const;

export const getLanguageCodeByCountry = (countryCode: keyof typeof countryToLanguage): string | undefined => {
    return countryToLanguage[countryCode];
};