import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '..';
import { EnquiryData } from '../enquirySummary/models/Enquiry';

// leads details
export interface LeadDetailsQueryParams {
    leadGuidId: string;
}

export interface LeadDetailsData {
    loading?: boolean;
    networkStatus?: number;
    leadDetails: LeadDetails;
}

export interface LeadDetails {
    leadGuid: string | null;
    createdDate: Date | null;
    lastUpdated: Date | null;
    isClosed: boolean | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    gender: string | null;
    initialEnquiryDate: Date | null;
    phone: string | null;
    mobile: string | null;
    sourceGuidID: string | null;
    source: string | null;
    officeGuidID: string | null;
    office: string | null;
    sourceNotes: string | null;
    organisation: string | null;
    positionAtOrganisation: string | null;
    natureOfEnquiry: string | null;
    practiceAreaGuidID: string | null;
    areaOfLaw: string | null;
    internalNotes: string | null;
    files: File[];
    dateAssigned: Date | null;
    assignedTo: string | null;
    assignedToName: string | null;
    assignedToEmailAddress: string | null;
    assignedBy: string | null;
    assignedByName: string | null;
    assignedByEmailAddress: string | null;
    followUpDueDate: Date | null;
    followUpActionGuidID: string | null;
    followUpAction: string | null;
    followUpDate: Date | null;
    followUpNotes: string | null;
    outcomeGuidID: string | null;
    outcome: string | null;
    outcomeDate: Date | null;
    reasonGuidID: string | null;
    reason: string | null;
}

// TODO: why duplicate ?
export function retrieveEnquiryDetailsData(query: LeadDetailsQueryParams,
                                           refreshData: boolean,
                                           onSuccess: (data: EnquiryData) => void,
                                           // tslint:disable-next-line:no-any
                                           onError: (reason: any) => void)
                                           : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: EnquiryDetailsData,
        variables: {
            guid: query.leadGuidId
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any) => { onError(reason); }
    );
}

// lead details history

export interface LeadDetailsHistoryQueryParams {
    offset: number | null;
    first: number | null;
    filter: String | null;
    sortColumn: String | null;
    sortDirection: String | null;
    entityGuidID: String | null;
}

export interface LeadDetailsHistoryData {
    loading?: boolean;
    networkStatus?: number;
    task: Task;
}

export interface Task {
    summaryList: TaskSummaryList;
}

export interface TaskSummaryList {
    tasks: TaskDetails[];
    recordCount: number;
    actualRowCountForSearch: number;
}

export interface TaskDetails {
    assignedByGuidID: null | string;
    assignedByName: null | string;
    assignedDate: Date | null;
    assignedToGuidID: null | string;
    assignedToName: string;
    completedDate: Date | null;
    createdDate: Date;
    dueDate: Date | null;
    dueDateUTC: Date | null;
    entityGuidID: string;
    entityType: number;
    ownerGuidID: string;
    ownerName: string;
    properties: string;
    taskDescription: null;
    taskGuidID: string;
    taskStatusDescription: string;
    taskStatusGuidID: string;
    taskTypeDescription: string;
    taskTypeInternalDescription: string;
    taskTypeGuidID: string;
    updatedDate: Date;
    followUpNotes: string;
    outcome: string;
    outcomeNotes: string;
    followUpAction: string;
    systemAction: string | null;
    followUpActionSystemName: string | null;
    actionedDescription: string | null;
    reason: string;
    isKeyOpportunity: boolean;
    notes: string;
    feeApplicable: number;
    bookMeetingStartDate: Date | null;
    bookMeetingEndDate: Date | null;
    bookMeetingStartDateUTC: Date | null;
    bookMeetingEndDateUTC: Date | null; 
    startDate: Date | null;
    firmOffice: string;
    firmOfficeGuid: string;
    timezone: string;
    timezoneOffset: Number;
    sendConfirmation: boolean;
    meetingNotes: string;
    staff: string;
    staffGuid: string;
    fromEmailAddress: string;
    toEmailAddresses: string;
    ccEmailAddresses: string;
    emailSubject: string;
    emailContent: string;
    emailTemplateName: string;
    referredToFirmGuid: string;
    referredToFirmName: string;
    notProceedingNotes: string;
    enquiryLinkedMatter: string;
    linkedMatterClient: string;
    linkedMatterTitle: string;
    isAssignedToTeam: boolean;
    canAssignMeeting: boolean;
    taskUpdateInProgress: boolean;
    isIntegrationFailed: boolean;
}

// export interface TaskSummaryCount {
//     calculatedRowCount:     number;
//     rowCountWithoutFilters: number;
// }

export function retrieveLeadDetailsHistoryData(query: LeadDetailsHistoryQueryParams,
                                               refreshData: boolean,
                                               onSuccess: (data: LeadDetailsHistoryData) => void,
                                            // tslint:disable-next-line:no-any
                                               onError: (reason: any) => void)
                                                : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: LeadDetailsHistoryData,
        variables: {
            offset: query.offset,
            first: query.first,
            filter: query.filter,
            sortColumn: query.sortColumn,
            sortDirection: query.sortDirection,
            entityGuidID: query.entityGuidID,
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any) => { onError(reason); }
    );
}

const EnquiryDetailsData = gql`
query EnquiryDetailsData($guid: String){
    enquiry{
        detail(guid: $guid){
            guid
            createdDate
            lastUpdated
            isClosed
            firstName
            lastName
            email
            gender
            initialEnquiryDate
            phone
            mobile            
            sourceGuidID
            source
            officeGuidID
            office
            sourceNotes
            organisation
            positionAtOrganisation
            natureOfEnquiry
            practiceAreaGuidID
            areaOfLaw
            dateAssigned
            assignedTo
            assignedToName
            assignedToEmailAddress
            assignedBy
            assignedByName
            assignedByEmailAddress
            followUpDueDate
            followUpActionGuidID
            followUpAction            
            followUpDate
            followUpNotes
            outcomeGuidID
            outcome
            outcomeDate
            reasonGuidID
            reason
            internalNotes
            internalNotesGuidID
            isKeyOpportunity
            files {
                entityType
                guidID
                name
                url
            }
        }
    }
}`;

const LeadDetailsHistoryData = gql`
query TaskSummaryData($offset: Int, $first: Int, $filter: String, $sortColumn: String, $sortDirection: String, $entityGuidID: String) {
    task {
      summaryList(offset: $offset, first: $first, filter: $filter, sortColumn: $sortColumn, sortDirection: $sortDirection, entityGuidID: $entityGuidID) {
        tasks {
            assignedByGuidID
            assignedByName
            assignedDate
            assignedToGuidID
            assignedToName
            completedDate
            createdDate
            dueDate
            dueDateUTC
            entityGuidID
            entityType
            ownerGuidID
            ownerName
            properties
            taskDescription
            taskGuidID
            taskStatusDescription            
            taskStatusGuidID
            taskTypeDescription
            taskTypeInternalDescription
            taskTypeGuidID
            updatedDate
            followUpNotes
            outcome
            outcomeNotes
            followUpAction
            systemAction
            followUpActionSystemName
            actionedDescription
            reason
            isKeyOpportunity,
            notes,
            feeApplicable,
            startDate,
            bookMeetingStartDate,
            bookMeetingEndDate,
            bookMeetingStartDateUTC,
            bookMeetingEndDateUTC,
            firmOffice,
            firmOfficeGuid,
            timezone,
            timezoneOffset,
            sendConfirmation,
            meetingNotes,
            staff,
            staffGuid,
            fromEmailAddress,
            toEmailAddresses,
            ccEmailAddresses,
            emailSubject,
            emailContent,
            emailTemplateName,
            referredToFirmGuid,
            referredToFirmName,
            notProceedingNotes,
            enquiryLinkedMatter,
            linkedMatterClient,
          	linkedMatterTitle,
            isAssignedToTeam,
            canAssignMeeting,
            taskUpdateInProgress,
            isIntegrationFailed
        }
        actualRowCountForSearch
        recordCount
      }
    }
  }
`;
