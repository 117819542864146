import gql from 'graphql-tag';

export interface DebtDetailInput {
    originalDebtAmount?: number;
    dateOfDebt?: Date;
    endDateOfDebt?: Date;
    invoiceNumber?: string;
    originalInterestAmount?: number;
    collectionCost?: number;
    accountManager?: string;
    instructions?: string;
}

export interface PartyDetailInput {
    partyType: string | null;
    partyCategory: 'individual' | 'organisation';
    name: string | null;
    email: string | null;
    dateOfBirth: Date | null;
    tradingName: string | null;
    contactName: string | null;
    companyNumber: string | null;
    businessPhoneNumber: string | null;
    homePhoneNumber: string | null;
    mobilePhoneNumber: string | null;
    faxNumber: string | null;
    addressName: string | null;
    floor: string | null;
    number: string | null;
    street: string | null;
    suburb: string | null;
    state: string | null;
    postcode: string | null;
    country: string | null;
    legalAddressName: string | null;
    legalFloor: string | null;
    legalNumber: string | null;
    legalStreet: string | null;
    legalSuburb: string | null;
    legalState: string | null;
    legalPostcode: string | null;
    legalCountry: string | null;
    pOBoxType: string | null;
    pOBox: string | null;
    postalState: string | null;
    postalSuburb: string | null;
    postalPostCode: string | null;
    postalCountry: string | null;
}

export interface UploadFile {
    name: string | null;
    tempFileName: string | null;
    guidID: string | null;
    status: number | null;
    // tslint:disable-next-line: no-any
    file: any | null;
}

export interface CreatePendingMatterInput {
    debtDetail: DebtDetailInput | null;
    partyDetails: PartyDetailInput[] | null;
    files: UploadFile[] | null;
}

export interface CreatePendingMatterMutationParams {
    input: CreatePendingMatterInput | null;
}

export interface CreatePendingMatterMutationResults {
    matter: {
        pendingMatter: {
            create: {
                error: string;
                status: boolean;
            }
        }
    };
}

export const CreatePendingMatterMutation = gql`
    mutation createPendingMatter($input: CreatePendingMatterInput) {
        matter {
            pendingMatter {      
                create(input: $input) {
                    status,
                    error
                }
            }
        }
    }
`;

export const CreatePendingMattersSubscription = gql`
    subscription createPendingMatterNotificationSubscription {
        createPendingMatterNotification{
            id,
            description,
            message,
            status
        }
    }
`;

export const CreateInstructMatterSubscription = gql`
    subscription createInstructMatterNotificationSubscription {
        createInstructMatterNotifications{
            id,
            description,
            message,
            status
        }
    }
`;

export const SendInstructMatterMutation = gql`
    mutation createInstructMatter($input: CreateInstructMatterInput ) {
        matter {
            instructMatter {
                create(input: $input) {
                    status
                    error
                }
            } 
        }
    }
`;