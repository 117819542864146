import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { serverUrl, showNotification } from './App';
import { unregister } from './registerServiceWorker';
import { ApolloLink, Operation, NextLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import {
    InMemoryCache,
    IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { App } from './App';
import './index.css';
import { globalVariableDefaults } from './globals';
import { getMainDefinition } from 'apollo-utilities';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHookProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-client/ApolloClient';
import { LicenseManager } from '@ag-grid-enterprise/core';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import MomentUtils from '@date-io/moment';
import { MatterManagementContextProvider } from './matterManagement/MatterManagementContext';
// import { EnquirySummaryContextProvider } from './enquirySummary/EnquirySummaryContext';
import { TasksSummaryContextProvider } from './tasksSummary/TasksSummaryContext';
import { isUploadRequest, multiPartLink } from './infrastructure/MultipartLink';
import TimerContextProvider from './contexts/TimerContext';
import './i18n';
import { MatterSummaryContextProvider } from './matterSummary/MatterSummaryContextProvider';

// tslint:disable-next-line:max-line-length
LicenseManager.setLicenseKey(
    // tslint:disable-next-line: max-line-length
    'Using_this_{AG_Grid}_Enterprise_key_{AG-052766}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{RED_RAIN_CORPORATION_PTY_LTD}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{RedView}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{RedView}_need_to_be_licensed___{RedView}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_March_2025}____[v3]_[01]_MTc0MTY1MTIwMDAwMA==8b8075b72f9426eefc5fc0ef3efde5da'
);

// tslint:disable-next-line:no-console
const GRAPHQL_ENDPOINT = serverUrl + '/api/graphql';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        __schema: {
            types: [
                {
                    kind: 'UNION',
                    name: 'PartyDetails',
                    possibleTypes: [
                        { name: 'Staff' },
                        { name: 'Individual' },
                        { name: 'Organisation' },
                        { name: 'Referrer' }
                    ]
            },
                {
                    kind: 'UNION',
                    name: 'TaskDetails',
                    possibleTypes: [                       
                        { name: 'GeneralTask' },
                        { name: 'ClientTask' },
                        { name: 'MatterTask' },
                        { name: 'PhoneTask' },
                        { name: 'FollowUpTask' },
                    ]
                },
                {
                  kind: 'INTERFACE',
                  name: 'TaskDetailTypeInterface',
                  possibleTypes: [
                        { name: 'GeneralTask' },
                        { name: 'ClientTask' },
                        { name: 'MatterTask' },
                        { name: 'PhoneTask' },
                        { name: 'FollowUpTask' }
                    ]
                }
            ]
        }
    }
});

const httpLink = new BatchHttpLink({
    uri: GRAPHQL_ENDPOINT,
    credentials: 'include'
});

const errorLink = onError(
    ({ graphQLErrors, networkError, response, operation }) => {
        // console.log('@Network error:', networkError);
        // console.log('[OnError response]:', response);
        // console.log('[OnError operation]:', operation);

        if (graphQLErrors) {
            // eslint-disable-next-line array-callback-return
            graphQLErrors.map(({ message, locations, path, extensions }) => {
                if (extensions && extensions.code === 'auth-required') {
                    window.location.replace('/unauthorised');
                }
                // console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
                showNotification(null, message, 'error');
            });
        }
        if (networkError) {
            showNotification(
                'Network error',
                networkError.message,
                'error',
                'SingleNotification'
            );
        }
    }
);

const consoleLink = new ApolloLink(
    (operation: Operation, forward: NextLink) => {
        // console.log(`@starting request for ${operation.operationName}`, operation);
        return forward(operation).map(data => {
            // console.log(`@ending request for ${operation.operationName}`);
            return data;
        });
    }
);

const wsClient = new SubscriptionClient(
    GRAPHQL_ENDPOINT.replace('https', 'wss'),
    {
        connectionParams: async () => {
            // get the authentication token from session storage if it exists
            const token = sessionStorage.getItem('accessToken');
            return {
                Authorization: token ? `Bearer ${token}` : '',
                RequestSource: 'connectweb'
            };
        },
        lazy: true,
        reconnect: true
        // tslint:disable-next-line:align
    },
    null,
    ['graphql-ws', 'connectweb']
);

const webSocketLink = new WebSocketLink(wsClient);

const authLink = setContext((_, { headers }) => {
    // console.log(`@setting context for headers`);
    // get the authentication token from session storage if it exists
    const token = sessionStorage.getItem('accessToken');
    // return the headers to the context so httpLink can read them
    if (token) {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : ''
            }
        };
    }

    return {
        headers: {
            ...headers
        }
    };
});

const requestLink = ApolloLink.split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    webSocketLink,
    consoleLink.concat(httpLink)
);

const multiPartRequestLink = ApolloLink.split(
    ({ variables }) => {
        return isUploadRequest(variables);
    },
    multiPartLink,
    requestLink    
);

const link = ApolloLink.from([authLink, errorLink, multiPartRequestLink]);

export const resetApolloClient = async () => {
    try {
        await client.resetStore();
    } catch (error) {
        // console.error('Error resetting client', error);
    }
};

export const cache = new InMemoryCache({ fragmentMatcher });

// const stateLink = withClientState({
//     cache,
//     defaults: globalVariableDefaults,
//     resolvers: {}
//   });

cache.writeData({
    data: {
        defaults: globalVariableDefaults
    }
});

export const client = new ApolloClient({
    connectToDevTools: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'ignore'
        },
        query: {
            fetchPolicy: 'cache-first',
            errorPolicy: 'all'
        },
        mutate: {
            errorPolicy: 'all'
        }
    },
    link: ApolloLink.from([
        // stateLink,
        errorLink,
        link
    ]),
    // link: errorLink.concat(link),
    cache: cache
    // This is what enables cancelation
    // queryDeduplication: false
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <ApolloHookProvider client={client}>
            <TimerContextProvider>
                {/* <EnquirySummaryContextProvider> */}
                <MatterManagementContextProvider>
                    <TasksSummaryContextProvider>
                        <MatterSummaryContextProvider>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <App />
                            </MuiPickersUtilsProvider>
                        </MatterSummaryContextProvider>
                    </TasksSummaryContextProvider>
                </MatterManagementContextProvider>
                {/* </EnquirySummaryContextProvider> */}
            </TimerContextProvider>
        </ApolloHookProvider>
    </ApolloProvider>,
    document.getElementById('root')
);
unregister();