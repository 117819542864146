/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { RouterProps } from '../App';
import { LearnMoreBanner } from './LearnMoreBanner';
import ReactPlayer from 'react-player/youtube';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
            flex: 1,
        },
        contentWrapper: {
            padding: '0 48px',
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
        },
        contentContainer: {
            backgroundColor: 'rgb(255, 255, 255)',
            boxShadow: 'rgba(81, 115, 255, 0.09) 0px 0px 14px 0px',
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            width: '100%',
            borderRadius: '8px',
        },
        iframeContainer: {
            // paddingBottom: '56.25%',
            position: 'relative',
            // height: 0,
            // width: '100%',
            flex: 1,
            '& iframe': {
                // left: 0,
                // top: 0,
                // height: '100%',
                // width: '100%',
                // position: 'absolute',
            },
        },
    }),
);

export enum LearnMoreEnum {
    LEARN_ABOUT_DASHBOARD = 0,
    LEARN_ABOUT_DRILLDOWNS,
    LEARN_ABOUT_PERSONALISING,
    LEARN_ABOUT_CREATING_VIEWS,
    FEE_EARNER_DASHBOARD,
    NEW_ENQUIRIES,
    New_Enquiry_Process_Overview,
    Add_New_Enquiries,
    Mailing_Lists,
    Managing_Users,
    Following_Up,
    Clients_And_Contacts,
    Managing_Referrers,
    USING_THE_OUTLOOK_ADD_IN,
    TASKS,
    MATTER_MANAGEMENT,
    TASK_MANAGEMENT_BASICS,
    DELEGATING_TASKS_TO_TEAMS,
    PHONE_MESSAGES,
    REPORTING_ON_ENQUIRIES,
    CONFIGURING_NEW_ENQUIRY_NOTIFICATIONS,
    FIRM_STRUCTURE,
    LOOKUP_LISTS,

    // lookup configuration
    area_of_law,
    client_and_contact_tags,
    dashboard_panel,
    enquiry_source,
    enquiry_follow_up_action,
    location,
    loss_reason,
    matter_follow_up_action,
    priority,
    po_box_type,
    referrer_source,
    referrer_type,
    relationship_quality,
    task_category,
    task_type,
    task_type_group,

    // timesheet
    timesheet_basics,
    managing_draft_time_items,
    timesheet_advanced,
    staff_budgets,

    // acuity resouce page
    Using_Firm_Dashboard,
    Using_Fee_Earner_Dashboard,
    Drilldowns,

    System_Configuration_Overview,
    Matter_Searching_Client,
    Matter_Searching_Client_Internal_User,
    Using_Matter_Lists,
    Creating_Views,
    Matter_Searching,
    Configuring_Dashboards,

    EnquiryBoardView,

    // Events & Campaigns
    EventsAndCampaigns,

    MatterForecast
}

interface LearnMorePageProps extends RouterProps {
    // tslint:disable-next-line: no-any
    location: any;
}

// interface LearnMorePageState {}

// tslint:disable-next-line: no-anyW
export const LearnMorePage: React.FunctionComponent<LearnMorePageProps> = (props) => {
    const classes = useStyles();

    const command = props.match.params.command;

    const onClickBack = () => {
        // history.goBack();
        props.history.goBack();
    };

    const getDocumentUrl = (): string => {
        if (LearnMoreEnum[command] === LearnMoreEnum.LEARN_ABOUT_DASHBOARD) {
            return 'https://redraincorp.sharepoint.com/:b:/s/RedViewProduct/EW9mRmJya29MrT_EsXVcKQYB6EnaEMaMp90CFsAvJATPuw?e=hrXUPC';
        }
        if (LearnMoreEnum[command] === LearnMoreEnum.FEE_EARNER_DASHBOARD) {
            return 'https://redraincorp.sharepoint.com/:b:/s/RedViewProduct/EW9mRmJya29MrT_EsXVcKQYB6EnaEMaMp90CFsAvJATPuw?e=hrXUPC';
        }
        return '';
    };

    const getTitleAndVideo = () => {
        switch (LearnMoreEnum[command]) {
            case LearnMoreEnum.LEARN_ABOUT_DASHBOARD: {
                return {
                    title: 'Learn About Dashboards',
                    url: 'TQdzR4-RYAE',
                };
            }
            case LearnMoreEnum.LEARN_ABOUT_DRILLDOWNS: {
                return {
                    title: 'Learn About DrillDowns',
                    url: 'OXI7LG4RsCI',
                };
            }
            case LearnMoreEnum.LEARN_ABOUT_PERSONALISING: {
                return {
                    title: 'Learn About Personalising',
                    url: 'tqh-SrQyFRQ',
                };
            }
            case LearnMoreEnum.FEE_EARNER_DASHBOARD: {
                return {
                    title: 'Learn About Dashboards',
                    url: 'GIx5b0G5-Mg',
                };
            }
            case LearnMoreEnum.NEW_ENQUIRIES: {
                return {
                    title: 'Learn About New Enquiries',
                    url: '9L9OD_N9C30',
                };
            }
            case LearnMoreEnum.New_Enquiry_Process_Overview: {
                return {
                    title: 'New Enquiry Process Overview',
                    url: 'omP7LOTLRBw',
                };
            }
            case LearnMoreEnum.Add_New_Enquiries: {
                return {
                    title: 'Adding New Enquiries',
                    url: '9L9OD_N9C30',
                };
            }
            case LearnMoreEnum.Mailing_Lists: {
                return {
                    title: 'Mailing Lists',
                    url: 'TffuE21NK9E',
                };
            }
            case LearnMoreEnum.Managing_Users: {
                return {
                    title: 'Managing Users',
                    url: '9RaYyBME5u0',
                };
            }
            case LearnMoreEnum.Following_Up: {
                return {
                    title: 'Following Up',
                    url: 'tAEmK8eYA3g',
                };
            }
            case LearnMoreEnum.Clients_And_Contacts: {
                return {
                    title: 'Clients & Contacts',
                    url: 'G4dFO0KwxXI',
                };
            }
            case LearnMoreEnum.Managing_Referrers: {
                return {
                    title: 'Managing Referrers',
                    url: 'a8O-BUnBG8g',
                };
            }
            case LearnMoreEnum.USING_THE_OUTLOOK_ADD_IN: {
                return {
                    title: 'Using the Outlook Add in',
                    url: 'gB7_iW2k1JQ',
                };
            }
            case LearnMoreEnum.TASKS: {
                return {
                    title: 'Basic Introduction',
                    url: '4qjAZ3r5yjQ',
                };
            }

            case LearnMoreEnum.MATTER_MANAGEMENT: {
                return {
                    title: 'Matter Management',
                    url: 'akbIXkYYeP0',
                };
            }

            case LearnMoreEnum.TASK_MANAGEMENT_BASICS: {
                return {
                    title: 'Task Management Basics',
                    url: '4qjAZ3r5yjQ',
                };
            }

            case LearnMoreEnum.DELEGATING_TASKS_TO_TEAMS: {
                return {
                    title: 'Delegating Tasks to Teams',
                    url: 'ushax3hQscA',
                };
            }

            case LearnMoreEnum.PHONE_MESSAGES: {
                return {
                    title: 'Phone Messages',
                    url: 'nE8p7lpsGlo',
                };
            }

            case LearnMoreEnum.REPORTING_ON_ENQUIRIES: {
                return {
                    title: 'Reporting on Enquiries',
                    url: 'xuNp-j7Zcbw',
                };
            }

            case LearnMoreEnum.CONFIGURING_NEW_ENQUIRY_NOTIFICATIONS: {
                return {
                    title: 'Configuring New Enquiry Notifications',
                    url: 'n2kHPRRvsRY',
                };
            }

            case LearnMoreEnum.FIRM_STRUCTURE: {
                return {
                    title: 'Firm Structure',
                    url: 'Stw6mHmODrU',
                };
            }

            case LearnMoreEnum.LOOKUP_LISTS: {
                return {
                    title: 'Lookup List',
                    url: 'oI9cRNfBQyM',
                };
            }

            case LearnMoreEnum.area_of_law: {
                return {
                    title: 'Area of Law Lookup',
                    url: 'JcbWxAUKhwM',
                };
            }

            case LearnMoreEnum.client_and_contact_tags: {
                return {
                    title: 'Client and Contact Tags',
                    url: 'UOUkSZJxCbA',
                };
            }

            case LearnMoreEnum.dashboard_panel: {
                return {
                    title: 'Dashboard Panel Lookup',
                    url: '91_12VYYvPY',
                };
            }

            case LearnMoreEnum.enquiry_source: {
                return {
                    title: 'Enquiry Source lookup',
                    url: 'lbNmpgD18kE',
                };
            }

            case LearnMoreEnum.enquiry_follow_up_action: {
                return {
                    title: 'Enquiry Follow up action lookup',
                    url: 'VQF5EjQde-4',
                };
            }

            case LearnMoreEnum.location: {
                return {
                    title: 'Location lookup',
                    url: '9kCLLBxrywI',
                };
            }

            case LearnMoreEnum.loss_reason: {
                return {
                    title: 'Loss Reason lookup',
                    url: 'onyKENebJt0',
                };
            }

            case LearnMoreEnum.matter_follow_up_action: {
                return {
                    title: 'Matter Follow Up action lookup',
                    url: 'fbq5AJ-XFtc',
                };
            }

            case LearnMoreEnum.priority: {
                return {
                    title: 'Priority look up',
                    url: 'fl5MWXrSm-0',
                };
            }

            case LearnMoreEnum.po_box_type: {
                return {
                    title: 'PO Box lookup',
                    url: 'KfQfGJ_m_Gs',
                };
            }

            case LearnMoreEnum.referrer_source: {
                return {
                    title: 'Referral Source lookup',
                    url: 'LDLGwzq2K_4',
                };
            }

            case LearnMoreEnum.referrer_type: {
                return {
                    title: 'Referrer Type lookup',
                    url: 'sVoPGaxmd5U',
                };
            }

            case LearnMoreEnum.relationship_quality: {
                return {
                    title: 'Relationship Quality lookup',
                    url: 'uNny9Z8pwsw',
                };
            }

            case LearnMoreEnum.task_category: {
                return {
                    title: 'Task Category lookup',
                    url: '3zGztKO400E',
                };
            }

            case LearnMoreEnum.task_type: {
                return {
                    title: 'Task Type and Task Group lookups',
                    url: 'hNZWDxxGAdU',
                };
            }

            case LearnMoreEnum.task_type_group: {
                return {
                    title: 'Task Type and Task Group lookups',
                    url: 'hNZWDxxGAdU',
                };
            }

            case LearnMoreEnum.timesheet_basics: {
                return {
                    title: 'Timesheet Introduction',
                    url: '0eh6NGrQYTg',
                };
            }

            case LearnMoreEnum.managing_draft_time_items: {
                return {
                    title: 'Managing Draft Timelines',
                    url: 'kU0MZ5fvPYI',
                };
            }

            case LearnMoreEnum.timesheet_advanced: {
                return {
                    title: 'Advanced Timesheet Features',
                    url: 'mep0e_qwlAs',
                };
            }

            case LearnMoreEnum.staff_budgets: {
                return {
                    title: 'Staff Budgets',
                    url: '0W0GUOSdqKY',
                };
            }

            case LearnMoreEnum.Using_Firm_Dashboard: {
                return {
                    title: 'Using the Firm Dashboard',
                    url: 'TQdzR4-RYAE',
                };
            }

            case LearnMoreEnum.Using_Fee_Earner_Dashboard: {
                return {
                    title: 'Using the Fee Earner Dashboard',
                    url: 'GIx5b0G5-Mg',
                };
            }

            case LearnMoreEnum.Drilldowns: {
                return {
                    title: 'Drilldowns',
                    url: 'OXI7LG4RsCI',
                };
            }

            case LearnMoreEnum.System_Configuration_Overview: {
                return {
                    title: 'RedView Admin Training - System Configuration Overview',
                    url: '4meOfWHoATA',
                };
            }

            case LearnMoreEnum.Matter_Searching_Client: {
                return {
                    title: 'RedView Connect - Matter Searching',
                    url: 'ode6Frrtm08',
                };
            }

            case LearnMoreEnum.LEARN_ABOUT_CREATING_VIEWS: {
                return {
                    title: 'Creating Views',
                    url: '5VQMS3l7Xus',
                };
            }

            case  LearnMoreEnum.Matter_Searching_Client_Internal_User : {
                return {
                    title: 'RedView Connect - Matter Searching',
                    url: '4MwWecLQl7U',
                };
            }

            case  LearnMoreEnum.Using_Matter_Lists : {
                return {
                    title: 'RedView Connect - Using Matter Lists',
                    url: '4MwWecLQl7U',
                };
            }

            case  LearnMoreEnum.Creating_Views : {
                return {
                    title: 'RedView Connect - Creating Views',
                    url: '5VQMS3l7Xus',
                };
            }

            case  LearnMoreEnum.Matter_Searching : {
                return {
                    title: 'Matter Searching',
                    url: 'ode6Frrtm08',
                };
            }

            case  LearnMoreEnum.Configuring_Dashboards : {
                return {
                    title: 'Configuring Dashboards',
                    url: 'vzPEiFSppBk',
                };
            }

            case  LearnMoreEnum.EnquiryBoardView : {
                return {
                    title: 'Using the Board View',
                    url: '8ONmz8fZkZc',
                };
            }
            case LearnMoreEnum.EventsAndCampaigns : {
                return {
                    title: 'Events & Campaigns',
                    url: 'PIv9u1A0Evc',
                };
            }

            case LearnMoreEnum.MatterForecast : {
                return {
                    title: 'Matter Forecast',
                    url: 'de27BeTdur0',
                };
            }

            default: {
                return {
                    title: 'Learn About Dashboards',
                    url: 'xQwRocJySXs',
                };
            }
        }
    };

    return (
        <div className={classes.root}>
            <LearnMoreBanner
                title={getTitleAndVideo().title}
                onClickBack={onClickBack}
                // tslint:disable-next-line: max-line-length
                isButtonLinkEnabled={LearnMoreEnum[command] === LearnMoreEnum.LEARN_ABOUT_DASHBOARD || LearnMoreEnum[command] === LearnMoreEnum.FEE_EARNER_DASHBOARD}
                link={getDocumentUrl()}
            />
            <div className={classes.contentWrapper}>
                <div className={`${classes.contentContainer}`}>
                    <div className={classes.iframeContainer}>
                        <ReactPlayer url={`https://www.youtube.com/embed/${getTitleAndVideo().url}`} width="100%" height="100%" controls={true} playing={true} />
                    </div>
                </div>
            </div>
        </div>
    );
};
