import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enTranslation } from './locales/enTranslation';
import { auTranslation } from './locales/auTranslation';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en-AU',
    lng: 'en-AU',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      'en-US': {
        translation: enTranslation
      },
      'en-AU': {
        translation: auTranslation
      },
    },
     // Additional configuration for the language detector
    detection: {
      order: ['localStorage', 'navigator'], // Customize the detection order
    },
  });

export default i18n;