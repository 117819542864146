/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, createStyles, FormControlLabel, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import validator from 'validator';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '../..';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { SettingNotification } from '../IntegrationConfigurationPage';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: 30,
        },
        textField: {
            width: '100%'
        },
        urlWrapper: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            margin: '0 -10px',
            '& > div': {
                margin: '0 10px',
            },
            '& > button': {
                margin: '0 10px',
            }
        }
    })
);

interface InnographyTabProps {
    subscriptionResponse: SettingNotification | null;
}

interface InnographyTabState {
    url?: string;
    urlHelpText: string;
    isValid: boolean;
    isEnabled: boolean;
    isLoading: boolean;
}

export default function InnographyTab(props: InnographyTabProps) {

    const classes = useStyles();

    const [state, setState] = useState<InnographyTabState>({
        urlHelpText: '',
        isValid: true,
        isEnabled: false,
        isLoading: true
    });

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, []);
    
    const fetchData = () => {
        retrieveInnographyConfigQueryData(
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveData = (data: InnographyConfiData) => {
        setState({
            ...state,
            url: data.settings.integrations.innography.config.url,
            isEnabled: data.settings.integrations.innography.config.isEnabled,
            isLoading: false
        });
    };

    // tslint:disable-next-line: no-any
    const onTextFieldChange = (event: any) => {
        setState({
            ...state,
            url: event.target.value
        });
    };

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.checked
            };
        });
    };

    const onValidateUrl = () => {

        setState((prevState) => {

            let isValid = false;
            let helpText = 'Url is not valid.';

            if (state.url && validator.isURL(state.url)) {
                isValid = true;
                helpText = 'Url is valid.';

                client
                .mutate({
                    mutation: UpdateInnographyConfigMutation,
                    variables: {
                        url: state.url,
                        isEnabled: state.isEnabled
                    },
                })
                // tslint:disable-next-line: no-any
                .then((results: { data: any }) => {
                    if (results.data) {
                        if (results.data.error === null || results.data.error === undefined) {
                            showNotification(null, 'Successfully submitted', 'info');     
                        } else {
                            showNotification('Failed to Innography', results.data.error, 'error');
                        }

                        setState((_prevState) => {
                            return {
                                ..._prevState,
                                isLoading: false
                            };
                        });
                    }
                })
                // tslint:disable-next-line:no-any
                .catch((reason: any) => {
                    showNotification('Failed to Innography', reason, 'error');
                    setState((_prevState) => {
                        return {
                            ..._prevState,
                            isLoading: false
                        };
                    });
                });

            }

            return {
                ...prevState,
                isValid: isValid,
                urlHelpText: helpText
            };
        });
    };
    
    return (
        <div className={classes.root}>
           <Grid container={true} spacing={3}>
                {state.isLoading ? (
                    <Grid item={true} xs={12} md={12} justify="center">
                        <RvLoader />
                    </Grid>
                ) : (
                    <>
                        <Grid item={true} xs={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checkedIcon={<CheckBoxIcon color="primary" />} 
                                        checked={state.isEnabled} 
                                        onChange={onCheckboxChange} 
                                        name="isEnabled" 
                                    />
                                }
                                label="Enabled"
                            />
                        </Grid>
                        <Grid item={true} xs={12} md={12}>
                            <div className={classes.urlWrapper}>
                                <TextField 
                                    label="URL" 
                                    required={true}
                                    name="url"
                                    value={state.url}
                                    className={classes.textField}
                                    onChange={onTextFieldChange}
                                    helperText={state.urlHelpText}
                                    error={!state.isValid}
                                    disabled={!state.isEnabled}
                                />
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} md={12} justify="flex-end">
                            <Button 
                                variant="outlined" 
                                color="primary"
                                onClick={onValidateUrl}
                            >
                                Save
                            </Button>
                        </Grid>
                    </>
                )}
           </Grid>
        </div>
    );
}

export interface InnographyConfiData {
    settings: Settings;
}

export interface Settings {
    integrations: Integrations;
}

export interface Integrations {
    innography: Innography;
}

export interface Innography {
    config: Config;
}

export interface Config {
    isEnabled: boolean;
    url:       string;
}

export function retrieveInnographyConfigQueryData(
    refreshData: boolean,
    onSuccess: (data: InnographyConfiData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: InnographyConfigQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const UpdateInnographyConfigMutation = gql`
    mutation UpdateInnographyConfigMutation($url: String, $isEnabled: Boolean) {
        integrations {
            innography {
                update(url: $url, isEnabled: $isEnabled) {
                    error
                    status
                }
            }
        }
    }
`;
const InnographyConfigQuery = gql`
query InnographyConfigQuery{
    settings{
        id,
        integrations{
            id,
            innography{
                id,
                config{
                    id,
                    isEnabled,
                    url
                }
            }
        }
    }
}`;